import React, { useEffect, useState } from "react";
import { X } from 'lucide-react';
import dollarImg from "../src/assests/dollars.svg";

const SelectCustomCardModal = ({
  showModal,
  closeModal,
  minAmount,
  maxAmount,
  provider,
  handleCardSelection
}) => {
  const [cardAmount, setCardAmount] = useState(0);

  const handleCardAmountChange = (e) => {
    let cardAmount = e.target.value;
    setCardAmount(parseInt(cardAmount));
  };
  return (
    <div className={`${showModal ? 'block' : 'hidden'} z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto text-white`} id="ModalScrollable" tabIndex="-1" aria-labelledby="ModalScrollableLabel" aria-hidden="true">
      <div className="sm:h-[calc(100%-3rem)] w-lg lg:w-607 my-6 lg:pt-64 mx-auto relative w-auto pointer-events-none">
        <div className={`max-h-full swap-modal standard-mode overflow-hidden border-solid shadow-lg relative flex flex-col w-full pointer-events-auto bg-table-dark bg-clip-padding rounded-md outline-none text-current`}>
        <button
          onClick={closeModal}
          className={`
            absolute 
            top-4 
            right-4 
            p-2 
            rounded-lg 
            transition-colors 
            duration-200 
            focus:outline-none 
            hover:ring-2 
            hover:ring-gray-200
            z-10
          `}
          aria-label="Close modal"
        >
          <X 
            size={24} 
            className="gray-text hover:text-gray-200" 
          />
        </button>
          <div className="flex-auto overflow-y-auto relative p-4">
            <div className="flex justify-between w-96 md:w-520 pb-3">
              <span className="text-left text-white">Please Enter the amount you want on your card:</span>
            </div>

            <div className="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-gray-200 rounded-b-md">
              <div className="gray-text flex justify-end items-center pt-2 w-96 lg:w-full">
                <div className="flex items-center justify-center">
                <span className="pe-3"><small className="gray-text">${minAmount} - ${maxAmount} USD</small></span>
                  <div className="search-width w-80">
                    <input
                      type="number"
                      value={cardAmount}
                      placeholder={`$${minAmount}-$${maxAmount}`}
                      onChange={handleCardAmountChange}
                      className="flex-1 py-2 px-2 address-frame rounded-l-md focus:outline-none text-white placeholder-gray-color"
                    />
                    <button
                      className="px-6 py-3 copy-btn text-white rounded-r-md"
                      disabled
                    >
                      <img src={dollarImg} alt="search logo" className="max-h-6"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 flex-wrap items-center justify-end pb-4 ps-4 pe-4 border-gray-200 rounded-b-md">
              <button type="button" className="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out rounded-lg" data-bs-dismiss="modal" 
                onClick={closeModal}
              >
                Close
              </button>
              <button className="inline-block px-6 py-2.5 green-btn text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                onClick={() => handleCardSelection({
                  currency: provider.currency,
                  provider: provider.provider,
                  amount: cardAmount.toString()
                })}
                disabled={(parseInt(cardAmount) > parseInt(maxAmount)) || (parseInt(cardAmount) < parseInt(minAmount))}
              >
                Select Card
              </button>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default SelectCustomCardModal;