import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate hook
import check from "../src/assests/check.svg";

const DisclaimerWarning = () => {
  const location = useLocation();
  const cardType  = location.state.type;
  const navigate = useNavigate(); // Initialize the useNavigate hook
  
  const goToCreateYourCard = () => {
    navigate("/select-country", { state: { type: cardType } });
  };

  const ConfirmButton = () => (
    <button
      className={`w-full ${cardType === 'gift' ? "green-btn" : "pink-btn"}`}
      onClick={goToCreateYourCard}
    >
      <span className="text-white flex justify-center items-center">
        <img src={check} alt="check" height="12" width="12"/>
        <span className="ps-3">Confirm</span>
      </span>
    </button>
  );

  return (
    <div className="absolute bg-nav-bar flex justify-center items-center mx-8 sm:mx-0 rounded-lg gray-border overflow-y-auto sm:w-full lg:w-880">
      <div className="text-white w-full">
        <div className="flex flex-col items-center">
          {/* heading */}
          <div className="flex items-center justify-start p-4">
            <h2 className="text-2xl font-bold mb-3">
              Welcome to PayPaw!
            </h2>
          </div>
          {/* body */}
          <div className="flex flex-col items-center px-4">
            <div className="pb-24 md:pb-4"> {/* Reduced padding for desktop */}
              <p>
                <span className="flex items-center justify-center">⚠️ Disclaimer for Prepaid Cards ⚠️</span>
                <br/>
                - Card Types: We offer Visa in USD and Visa in USD (Global).
                <br/>
                - Acceptance:
                <br/>
                &emsp;- USD Visa works everywhere, it's compatible with Apple/Google/Samsung wallets, and works online as well.
                <br/>
                &emsp;- USD Visa (GLOBAL) has limited acceptance rate, it's compatible with Apple/Google/Samsung wallet but does not work everywhere, users in EU reported that it's accepted at major retailers, but it's a hit or miss in other places and online
                <br/>
                <br/>
                &emsp;- Delivery Time: Receiving your prepaid card may take up to 2 hours. However, it is usually delivered within 15 minutes.
                <br/>
                <br/>
                Please note, these cards are might not be accepted everywhere, so start with a small amount, to test them out for your needs.
                <br/>
                <br/>
                Email is only used to provide you with a copy of your purchase. A valid email must be provided.
                <br/>
                <br/>
                By proceeding, you acknowledge and accept the terms and conditions related to the use of prepaid cards provided through our service.
                <br/>
              </p>
            </div>
            
            {/* Desktop button (hidden on mobile) */}
            <div className="hidden md:block pb-4">
              <ConfirmButton />
            </div>
            
            {/* Mobile fixed button (hidden on desktop) */}
            <div className="fixed bottom-0 left-0 right-0 p-4 bg-nav-bar border-t border-gray-700 md:hidden">
              <ConfirmButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerWarning;
